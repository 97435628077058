<template>
  <div>
    <section>
      <div class="d-flex align-center pt-15 space-between">
        <button
          @click="back"
          class="pointer d-flex center"
          :title="tip && $t('attendees.tip.close')"
        >
          <s-icon color="green">close</s-icon>
          <div class="green--text">{{ $t("attendees.close") }}</div>
        </button>
        <div v-if="!$screen.sm">
          <s-btn
            v-show="tab === 0"
            @click.native="newAttendee"
            class="green addButton ml-10"
            :title="tip && $t('attendees.tip.add')"
          >
            <s-icon width="21" class="pr-10" color="white">account-plus</s-icon>
            <div class="grow">
              <div>{{ $t("attendees.add_attendee") }}</div>
            </div>
          </s-btn>

          <s-btn
            v-show="tab === 1"
            @click.native="newGroup"
            class="green addButton ml-10"
            :title="tip && $t('attendees.tip.add_group')"
          >
            <s-icon width="21" class="pr-10" color="white"
              >account-multiple-plus</s-icon
            >
            <div class="grow">
              <div>{{ $t("attendees.add_group") }}</div>
            </div>
          </s-btn>
        </div>
      </div>
      <hr class="my-10" />

      <section class="d-flex py-15 space-between align-center">
        <div
          class="d-flex searchBar"
          :title="tip && $t('attendees.tip.search')"
        >
          <s-text-field
            v-model="search"
            autofocus
            ref="search"
            :placeholder="$t('attendees.search')"
            block
          ></s-text-field>
          <div class="box center pointer" @click="focus()">
            <s-icon color="grey" class="">account-search-outline</s-icon>
          </div>
        </div>

        <s-btn
          v-show="tab === 0 && $screen.sm"
          @click.native="newAttendee"
          class="green addButton ml-10"
          :title="tip && $t('attendees.tip.add')"
        >
          <s-icon width="21" class="pr-10" color="white">account-plus</s-icon>
          <div class="grow">
            <div>{{ $t("attendees.add_attendee") }}</div>
          </div>
        </s-btn>

        <s-btn
          v-show="tab === 1 && $screen.sm"
          @click.native="newGroup"
          class="green addButton ml-10"
          :title="tip && $t('attendees.tip.add_group')"
        >
          <s-icon width="21" class="pr-10" color="white"
            >account-multiple-plus</s-icon
          >
          <div class="grow">
            <div>{{ $t("attendees.add_group") }}</div>
          </div>
        </s-btn>
      </section>

      <div class="row gap-1">
        <button
          ref="tab1"
          class="tab"
          :class="tab == 0 ? 'tab-active' : 'tab-not-active'"
          @click="changeTab(0)"
        >
          {{ $t("attendees.attendees") }}
        </button>
        <button
          ref="tab2"
          class="tab"
          :class="tab == 1 ? 'tab-active' : 'tab-not-active'"
          @click="changeTab(1)"
        >
          {{ $t("attendees.groups") }}
        </button>
      </div>
      <hr class="my-10" />
    </section>
    <transition name="fade" mode='out-in' >
      <Attendee v-if="tab === 0" :search="search" />
      <Group v-if="tab === 1" :search="search" />
    </transition>
  </div>
</template>

<script>
import { mapState } from "vuex";
import Attendee from "./Attendee.vue";
import Group from "./Groups.vue";
export default {
  components: {
    Attendee,
    Group,
  },
  data() {
    return {
      search: "",
      tab: 0,
    };
  },
  methods: {
    back() {
      this.$router.go(-1);
    },
    newAttendee() {
      this.$store.commit("attendees/clearAttendee");
      this.$store.commit("modals/attendeeModal", true);
    },
    newGroup() {
      this.$store.commit("attendees/clearAttendee");
      this.$store.commit("modals/addGroup", true);
    },
    focus() {
      this.$refs.search.onFocus();
    },
    changeTab(val) {
      this.tab = val;
      this.$refs.tab1.blur();
      this.$refs.tab2.blur();
    },
  },
  computed: {
    ...mapState("user", ["tip"]),
  },
  mounted() {
    this.$store.dispatch("attendees/getAttendeesInformation");
  },
};
</script>

<style lang="less" scoped>
.box {
  min-width: 40px;
  min-height: 40px;
  width: 40px;
  height: 40px;
  background-color: #151b23;
  margin-left: 2px;
}

.searchBar {
  max-width: 480px;
  width: 100%;
}

.addButton {
  min-width: min-content;
}

.tab {
  padding: 10px;
  transition: background-color 0.2s ease;
  background-color: rgba(64, 152, 117, 0.2);
  min-width: 100px;
}

.tab-active {
  background-color: rgba(64, 152, 117, 0.9);
}

.tab-not-active:hover {
  background-color: rgba(64, 152, 117, 0.3);
}

.tab:focus {
  outline: 1px solid var(--green);
}

@media only screen and (max-width: 576px) {
  .searchBar {
    max-width: none;
    width: 100%;
  }
}
</style>