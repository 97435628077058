<template>
  <div>
    <section>
      <div class="grid uppercase" v-if="$screen.lg">
        <div
          v-for="item in headings"
          :key="item.idx"
          class="align-center d-col py-15 lightBg"
        >
          <s-icon height="21" color="grey">{{ item.icon }}</s-icon>
          <div class="opacity-60 font-12 pt-10 hide_on_mobile">
            {{ item.title }}
          </div>
        </div>
      </div>
      <hr class="hide" />
    </section>
    <section class="pt-10 table">
      <div v-for="item in filtedItems" :key="item.id" class="row">
        <div class="grid uppercase gap-1" v-if="!$screen.lg">
          <div
            v-for="item in headings"
            :key="item.idx"
            :title="item.title"
            class="align-center d-col headingIcon lightBg center"
          >
            <s-icon height="21" color="grey">{{ item.icon }}</s-icon>
          </div>
        </div>
        <div class="grid grow">
          <div class="item d-flex">
            <div
              class="colorBox relative pointer"
              @click="openPicker(item)"
              :style="{ backgroundColor: 'var(--sm-color-' + item.color + ')' }"
            >
              <s-icon class="rotate">menu-right</s-icon>
              <s-color-picker
                v-click-outside="closePicker"
                v-model="item.color"
                @input_Event="updateColor"
                v-if="item.id == pickerID"
              ></s-color-picker>
            </div>
            <s-text-field
              block
              @keyup.native.enter="update(item)"
              @blur="update(item)"
              @onFocus="initSelect(item)"
              v-model="item.name"
            />
          </div>
          <s-text-field class="item" disabled :value="count(item.attendees)" />
          <s-text-field
            class="item"
            disabled
            :value="getAttendeesByEmail(item.attendees)"
          />
          <div class="d-flex">
            <button
              @click="edit(item)"
              :title="tip && $t('attendees.tip.edit')"
              class="item grow mr-1 darkBg center pointer hover"
            >
              <s-icon color="grey">square-edit-outline</s-icon>
            </button>
            <button
              @click="del(item)"
              :title="tip && $t('attendees.tip.delete')"
              class="item grow ml-1 darkBg center pointer hover"
            >
              <s-icon color="grey">trash-can-outline</s-icon>
            </button>
          </div>
        </div>
      </div>
    </section>
  </div>
</template>

<script>
import { mapState } from "vuex";
export default {
  props: ["search"],
  data() {
    return {
      items: [],
      filtedItems: [],
      pickerID: -1,
      selected: {},
    };
  },
  methods: {
    initSelect(item) {
      if (!item) return;
      this.selected = JSON.stringify(item);
    },
    async update(item) {
      let a = JSON.parse(this.selected);
      let b = { ...item };
      if (a.lang) a.lang = a.lang.value;
      if (b.lang) b.lang = b.lang.value;
      if (JSON.stringify(a) === JSON.stringify(b)) return;
      this.$store.commit("attendees/editAttendee", b);
      this.$store.commit("modals/updateAttendeeConfirmation", true);
    },
    back() {
      this.$router.go(-1);
    },
    getAttendeesByEmail(val) {
      let attendees = this.$store.state.attendees.attendees;
      let selected = val;
      let res = "";
      if (typeof val !== "object") selected = JSON.parse(val);
      for (let x of selected) {
        let item = attendees.find((el) => el.email == x);
        res = res.concat(item.email, ", ");
      }
      res = res.slice(0, res.length - 2);
      return res;
    },
    count(val) {
      if (typeof val !== "object") return JSON.parse(val).length;
      return val.length;
    },
    async del(item) {
      this.$store.commit("attendees/editAttendee", item);
      this.$store.commit("modals/deleteAttendeeConfirmationModal", true);
    },
    edit(val) {
      this.$store.commit("attendees/editAttendee", val);
      this.$store.commit("modals/addGroup", true);
    },
    openPicker(val) {
      this.pickerID = val.id;
      this.$store.commit("attendees/editAttendee", val);
    },
    closePicker() {
      this.pickerID = -1;
    },
    newAttendee() {
      this.$store.commit("attendees/clearAttendee");
      this.$store.commit("modals/attendeeModal", true);
    },
    async updateColor(val) {
      this.$store.commit("attendees/editColor", val);
      await this.$store.dispatch("attendees/updateGroups");
      this.$store.dispatch("attendees/getGroups");
      this.closePicker();
    },
    selectLanguage(item) {
      if (item?.display) item = item.value;
      if (!item) item = "en";
      let lang = JSON.parse(JSON.stringify(this.languages));
      if (typeof item !== "string") return;
      let find = lang.find((el) => el.value == item);
      if (!find || !item) lang[0].selected = true;
      else find.selected = true;
      return lang;
    },
    filterSearch() {
      this.filtedItems = this.items.filter((val) => {
        if (!val.name) val.name = "";
        if (!val.email) val.email = "";
        if (!val.phone) val.phone = "";
        let term = this.search.replace(/[-\/\\^$*+?.()|[\]{}]/g, "\\$&");
        let name = val.name.toLowerCase().search(term.toLowerCase()) !== -1;
        let email = val.email.toLowerCase().search(term.toLowerCase()) !== -1;
        let phone = val.phone.toLowerCase().search(term.toLowerCase()) !== -1;
        return name || email || phone;
      });
    },
    focus() {
      this.$refs.search.onFocus();
    },
  },
  computed: {
    ...mapState("user", ["tip"]),
    attendees() {
      let attendees = this.$store.state.attendees.groups;
      return JSON.parse(JSON.stringify(attendees));
    },
    headings() {
      return [
        { icon: "form-textbox", title: this.$t('attendees.group_name') },
        { icon: "abacus", title: this.$t('attendees.total') },
        { icon: "account-group-outline", title: this.$t('attendees.attendees') },
        {
          icon: "cursor-default-click-outline",
          title: this.$t("attendees.actions"),
        },
      ];
    },
  },
  watch: {
    attendees(val) {
      let items = JSON.parse(JSON.stringify(val));
      this.items = items;
      this.filterSearch();
    },
    search(val) {
      let items = JSON.parse(JSON.stringify(this.attendees));
      this.items = items;
      this.filterSearch();
    },
  },
  mounted() {
    this.filtedItems = this.attendees;
    this.$store.dispatch("attendees/getGroups");
  },
};
</script>

<style lang="less" scoped>
.table {
  max-height: calc(100vh - 252px);
  min-height: calc(100vh - 367px);
  overflow-y: auto;
  height: 100%;
  padding: 10px 0px;
  box-sizing: border-box;
}

.grid {
  display: grid;
  grid-template-columns: 1fr 1fr 3fr 110px;
  grid-column-gap: 2px;
}

.item {
  color: rgb(180, 180, 180);
  border-bottom: 1px solid #42515e;
  min-height: 36px;
  font-size: 16px;
}

.hover:hover {
  background-color: rgba(0, 0, 0, 0.45);
}

.box {
  min-width: 40px;
  min-height: 40px;
  width: 40px;
  height: 40px;
  background-color: #151b23;
  margin-left: 2px;
}

.colorBox {
  min-width: 20px;
  width: 20px;
  background-color: #151b23;
  margin-right: 5px;
  margin-top: 2px;
  margin-bottom: 2px;
  display: flex;
  align-items: flex-end;
  justify-content: flex-start;
}

.rotate {
  transform: translate(4px, 8px) rotate(45deg);
  opacity: 0;
}

.rotate:hover {
  opacity: 1;
}

.darkBg {
  background-color: rgba(0, 0, 0, 0.35);
}

.lightBg {
  background-color: rgba(0, 0, 0, 0.15);
}

.headingIcon {
  height: 40px;
  width: 40px;
  aspect-ratio: 1/1;
}

button:focus {
  background-color: rgba(255, 255, 255, 0.1);
}

@media only screen and (max-width: 991px) {
  .grid {
    display: flex;
    flex-direction: column;
  }

  .table {
    display: flex;
    flex-direction: column;
    gap: 25px;
    max-height: calc(100vh - 252px - var(--footer-height));
  }

  .hide {
    display: none;
  }
}

@media only screen and (max-width: 576px) {
  .grid {
    grid-template-columns: 1fr 1fr 2fr 1fr 1fr 80px;
  }

  .table {
    max-height: calc(100vh - 257px);
  }
}
</style>